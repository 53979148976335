import { ref, markRaw } from "vue";

const toasts = ref([]);
let counter = 0;

export const useToast = () => {
  const addToast = (config) => {
    const id = counter++;
    const toast = {
      id,
      type: config.type || "success",
      title: config.title || "",
      message: config.message || "",
      autoHide: config.autoHide !== undefined ? config.autoHide : true,
      delay: config.delay || 5000,
      visible: true,
    };

    toasts.value.push(toast);

    // Auto-remove from array after toast is hidden (if autohide is enabled)
    if (toast.autoHide) {
      setTimeout(() => {
        removeToast(id);
      }, toast.delay + 500); // Add a little extra time for animation
    }

    return id;
  };

  const removeToast = (id) => {
    const index = toasts.value.findIndex((toast) => toast.id === id);
    if (index !== -1) {
      toasts.value.splice(index, 1);
    }
  };

  // Convenience methods
  const success = (title, message, options = {}) => {
    return addToast({ type: "success", title, message, ...options });
  };

  const info = (title, message, options = {}) => {
    return addToast({ type: "info", title, message, ...options });
  };

  const warning = (title, message, options = {}) => {
    return addToast({ type: "warning", title, message, ...options });
  };

  const error = (title, message, options = {}) => {
    return addToast({ type: "danger", title, message, ...options });
  };

  return {
    toasts,
    addToast,
    removeToast,
    success,
    info,
    warning,
    error,
  };
};
